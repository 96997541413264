import React from 'react'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import { SliceZone, Layout } from '../components'

const StyledBody = styled.div`
    display: grid;
    justify-items: center;
    text-align: center;
  padding: 0 4em;
`

class About extends React.Component {
  render() {
    const {
      data: { about },
    } = this.props
    console.log(about)

    return (
      <Layout>
        <StyledBody>
          <SliceZone allSlices={about.data.body} />
        </StyledBody>
      </Layout>
    )
  }
}

export default About

export const pageQuery = graphql`
  query AboutQuery {
    about: prismicAboutPage {
      data {
        body {
          ... on PrismicAboutPageBodyText {
            slice_type
            id
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicAboutPageBodyQuote {
            slice_type
            id
            primary {
              quote {
                html
                text
              }
            }
          }
          ... on PrismicAboutPageBodyImage {
            slice_type
            id
            primary {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
